const RouterUtils = {};

/**
 * Returns an object with the route's resource ID
 * and a flag that declares if is changed (of if it's new)
 * and thus actions should be taken.
 *
 * @param to the 'to' route object (current route)
 * @param from the 'from' route object (previous route)
 * @returns {{toId: null, mustInitialize: boolean, fromId: null}}
 */
RouterUtils.resourceIdHelper = function (to, from) {
    if (to === null) throw new Error('to must not be null!');

    // We expect that to is never null.
    let toId;
    let fromId;
    let mustInitialize = false;
    if (from === null || from === undefined) {
        toId = to.params.id;
        mustInitialize = true;
    } else {
        toId = to.params.id;
        fromId = from.params.id;
        if (toId !== fromId) {
            mustInitialize = true;
        }
    }
    if (toId === undefined) toId = null; // In case 'id' is optional.
    if (fromId === undefined) fromId = null;

    return {
        mustInitialize: mustInitialize,
        toId: toId,
        fromId: fromId
    };
};

export default RouterUtils;
